"use client";

import { getRefreshAuthUri } from "@bay1/data/helpers/uri";
import {
  IntegrationEnvironment,
  RefreshAdminUserAccessDocument,
  RefreshAdminUserAccessMutation,
  UserRole,
} from "@bay1/sdk/generated/graphql";
import { DASHBOARD_URL, OPS_URL } from "@bay1/ui/urlHelper";
import { deleteCookie, getCookie, hasCookie, setCookie } from "cookies-next";
import { GraphQLClient } from "graphql-request";
import jwtDecode, { JwtPayload } from "jwt-decode";
import { NextRouter } from "next/router";
import type { User } from "next-auth";
import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import type { KeyedMutator } from "swr";
import useSWR from "swr";
import type { DeepReadonly } from "ts-essentials";

import { useInterval } from "./hooks";
import type { ActiveOrganizationStorage } from "./localStorageProxy";
import { localStorageProxy } from "./localStorageProxy";
import OrganizationDisplay from "./OrganizationDisplay";
import { OrganizationWithJWT } from "./types";

export interface SessionUser extends User {
  readonly roles?: UserRole[];
}
export interface CommonAppState {
  readonly organizations?: OrganizationWithJWT[];
  readonly activeOrganization?: OrganizationWithJWT;
  readonly user?: SessionUser;
  readonly loading: boolean;
  readonly loginError?: { message: string };
  readonly triggerTokenRefresh?: () => void;
  readonly clearAppState?: () => void;
  readonly localStore?: ActiveOrganizationStorage | undefined;
  readonly mutate?: KeyedMutator<OrganizationWithJWT[]>;
}
const localStore = localStorageProxy();

export const CommonAppContext = createContext<CommonAppState>({
  loading: true,
  triggerTokenRefresh: undefined,
  clearAppState: undefined,
  localStore,
});

/**
 * Session fetcher function used by SWR in the provider component.
 * Gets the current user session from the NextAuth.js API route. Note
 * that only when the `shouldRefreshToken` state is true will this
 * fetch the session.
 */
const sessionFetcher = async (
  _key: string,
  shouldRefreshToken: boolean,
  setShouldRefreshToken: Dispatch<SetStateAction<boolean>>,
  setTimeSinceLastRefresh: Dispatch<SetStateAction<number>>,
  setOldSession: Dispatch<
    SetStateAction<
      | {
          user: SessionUser;
        }
      | undefined
    >
  >,
) => {
  if (shouldRefreshToken) {
    try {
      const session = await fetch(`${DASHBOARD_URL}/api/auth/session`, {
        credentials: "include",
      }).then(async (response) => await response.json());
      if (session.user === undefined) {
        throw new global.Error("Could not fetch session.");
      }
      setOldSession(session as { user: SessionUser; expires: string });
      setShouldRefreshToken(false);
      setTimeSinceLastRefresh(Date.now());

      return session;
    } catch {
      if (hasCookie("ops-grantAdminUserAccess")) {
        const adminUserAccessCookie = JSON.parse(
          getCookie("ops-grantAdminUserAccess") as string,
        );

        setOldSession({
          user: {
            id: adminUserAccessCookie.userId,
            email: adminUserAccessCookie.email,
          },
        });
        setShouldRefreshToken(false);
        setTimeSinceLastRefresh(Date.now());
        return {
          user: {
            id: adminUserAccessCookie.userId,
            email: adminUserAccessCookie.email,
          },
        };
      }
      return null;
    }
  }
};

/**
 * Organizations fetcher function used by SWR in the provider component.
 * Gets the user organizations from the custom `/api/jwt` API route.
 */
const organizationsFetcher = async (
  _key: string,
  oldOrganizations: DeepReadonly<OrganizationWithJWT[]> | undefined,
  setOldOrganizations: Dispatch<
    SetStateAction<OrganizationWithJWT[] | undefined>
  >,
  refreshingAdminAccessToken: boolean,
) => {
  const response = await fetch(`${DASHBOARD_URL}/api/jwt`, {
    credentials: "include",
  });

  if (
    oldOrganizations?.find(
      (organization) => organization.isAdminUserAccessOrg,
    ) &&
    !refreshingAdminAccessToken
  ) {
    setOldOrganizations(oldOrganizations as OrganizationWithJWT[]);
    return oldOrganizations;
  }

  if (hasCookie("ops-grantAdminUserAccess")) {
    const adminUserAccessCookie = JSON.parse(
      getCookie("ops-grantAdminUserAccess") as string,
    );

    const agentRoles = jwtDecode<JwtPayload & { br: string[] }>(
      adminUserAccessCookie.jwt,
    ).br;

    const adminUserAccessOrganization = [
      {
        jwt: adminUserAccessCookie.jwt,
        id: adminUserAccessCookie.id,
        profile: adminUserAccessCookie.profile,
        roles: [UserRole.ADMIN],
        isAdminUserAccessOrg: true,
        adminUserAccessOrgExpires: adminUserAccessCookie.expires,
        agentRoles,
      },
    ];

    setOldOrganizations(adminUserAccessOrganization);
    return adminUserAccessOrganization;
  }
  if (response.ok) {
    const data = await response.json();

    if (Reflect.has(data, "message")) {
      throw new global.Error(data.message);
    }

    if (oldOrganizations) {
      const castData = data as OrganizationWithJWT[];
      const orgIds = oldOrganizations.map((organization) => organization.id);
      const newOrgIds = castData.map((organization) => organization.id);
      if (JSON.stringify(orgIds) === JSON.stringify(newOrgIds)) {
        return oldOrganizations;
      }
    }
    setOldOrganizations(data as OrganizationWithJWT[]);
    return data;
  }

  // Active redirect
  // List of locations that if fail to obtain org should redirect to signin
  if (
    window.location.pathname.startsWith("/organization") ||
    window.location.host.startsWith("dashboard") ||
    window.location.port === "4000"
  ) {
    // eslint-disable-next-line fp/no-mutation

    if (!hasCookie("ops-grantAdminUserAccess")) {
      // eslint-disable-next-line fp/no-mutation
      window.location.href = getRefreshAuthUri(window.location.href);
      return;
    }

    const adminUserAccessCookie = JSON.parse(
      getCookie("ops-grantAdminUserAccess") as string,
    );

    const agentRoles = jwtDecode<JwtPayload & { br: string[] }>(
      adminUserAccessCookie.jwt,
    ).br;

    const adminUserAccessOrganization = [
      {
        jwt: adminUserAccessCookie.jwt,
        id: adminUserAccessCookie.id,
        profile: adminUserAccessCookie.profile,
        roles: [UserRole.ADMIN],
        isAdminUserAccessOrg: true,
        adminUserAccessOrgExpires: adminUserAccessCookie.expires,
        agentRoles,
      },
    ];

    setOldOrganizations(adminUserAccessOrganization);
    return adminUserAccessOrganization;
  }
};

export const CommonAppContextProvider = ({
  children,
  router,
}: DeepReadonly<PropsWithChildren<{ router: NextRouter }>>): JSX.Element => {
  const [timeSinceLastRefresh, setTimeSinceLastRefresh] = useState(Date.now());
  const [shouldRefreshToken, setShouldRefreshToken] = useState<boolean>(true);
  const [activeOrganization, setActiveOrganization] = useState<
    OrganizationWithJWT | undefined
  >();
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [isFetchingAdminToken, setIsFetchingAdminToken] = useState(false);
  const [agentIsActive, setAgentIsActive] = useState(false);

  const activeOrganizationId = getCookie("highnote.active-organization", {
    httpOnly: false,
    path: "/",
    secure: process.env.NEXT_PUBLIC_AUTH_COOKIE_SSL === "true",
    sameSite: "lax",
    domain: process.env.NEXT_PUBLIC_AUTH_COOKIE_DOMAIN ?? "localhost",
  });

  const [oldSession, setOldSession] = useState<
    { user: SessionUser; expires: string } | undefined
  >(undefined);
  const [oldOrganizations, setOldOrganizations] = useState<
    OrganizationWithJWT[] | undefined
  >(undefined);

  /**
   * This function decides whether or not to trigger a token refresh. The
   * way the token refresh gets triggered is by checking a timestamp stored
   * in state called `timeSinceLastRefresh`. If the difference between the two
   * is over 7.5 minutes, we set the `shouldRefreshToken` state to true.
   */
  const triggerTokenRefresh = useCallback(() => {
    const now = Date.now();
    const diff = (now - timeSinceLastRefresh) / 1000;

    if (diff > 60 * 7.5) {
      setShouldRefreshToken(true);
    }
  }, [timeSinceLastRefresh]);

  /**
   * This function clears the state items provided through the context
   * as well as any cookies we've set.
   */
  const clearAppState = () => {
    localStore.setRedirectURL("");
    setIsLoggingOut(true);
    deleteCookie("ops-grantAdminUserAccess", {
      httpOnly: false,
      path: "/",
      secure: process.env.NEXT_PUBLIC_AUTH_COOKIE_SSL === "true",
      sameSite: "lax",
      domain: process.env.NEXT_PUBLIC_AUTH_COOKIE_DOMAIN ?? "localhost",
    });
    setActiveOrganization(undefined);

    deleteCookie("highnote.active-organization", {
      httpOnly: false,
      path: "/",
      secure: process.env.NEXT_PUBLIC_AUTH_COOKIE_SSL === "true",
      sameSite: "lax",
      domain: process.env.NEXT_PUBLIC_AUTH_COOKIE_DOMAIN ?? "localhost",
    });

    setOldSession(undefined);
    setOldOrganizations(undefined);
    setShouldRefreshToken(true);
  };

  /**
   * Get the latest session and return it in `temporaryState` while also
   * preserving it in the `oldSession` state.
   */
  const { data: temporarySession } = useSWR<{
    user: SessionUser;
    expires: string;
  }>(
    [
      "session",
      shouldRefreshToken,
      setShouldRefreshToken,
      setTimeSinceLastRefresh,
      setOldSession,
    ],
    sessionFetcher,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      refreshWhenHidden: true,
    },
  );

  /**
   * If `oldSession` has data but `temporarySession` is null,
   * then the session has expired and the user needs to log back in.
   */
  if (oldSession && temporarySession === null) {
    // eslint-disable-next-line fp/no-mutation
    window.location.href = getRefreshAuthUri(window.location.href);
  }

  const session = temporarySession ?? oldSession;

  const isLoading = session === undefined;

  /**
   * This is an effect that runs every 10 seconds and checks if the session
   * has expired. If it has, we set the `shouldRefreshToken` state to true.
   * Admin user access to the dashboard is also managed here.
   */
  useInterval(async () => {
    if (session) {
      const expiresDate = new Date(session.expires).getTime();
      const now = Date.now();
      const diff = (expiresDate - now) / 1000;
      if (diff < 0) {
        setShouldRefreshToken(true);
      }
    }

    if (activeOrganization?.isAdminUserAccessOrg) {
      const adminUserAccessOrgExpiresDate = new Date(
        activeOrganization.adminUserAccessOrgExpires as string,
      ).getTime();
      const now = Date.now();
      const diff = (adminUserAccessOrgExpiresDate - now) / 1000;
      // Route back to ops as token has already expired and cannot be refreshed
      if (diff < 0) {
        router.push(OPS_URL);
        deleteCookie("ops-grantAdminUserAccess", {
          httpOnly: false,
          path: "/",
          secure: process.env.NEXT_PUBLIC_AUTH_COOKIE_SSL === "true",
          sameSite: "lax",
          domain: process.env.NEXT_PUBLIC_AUTH_COOKIE_DOMAIN ?? "localhost",
        });
      }
      if (diff < 120 && diff > 0 && !isFetchingAdminToken) {
        const client = new GraphQLClient(
          `${
            activeOrganization.profile.environment ===
            IntegrationEnvironment.TEST
              ? process.env.NEXT_PUBLIC_TEST_API_URL
              : process.env.NEXT_PUBLIC_API_URL
          }/graphql`,
          {
            headers: {
              Authorization: `Bearer ${activeOrganization.jwt}`,
            },
          },
        );

        // Fetch new token if agent is active
        if (agentIsActive) {
          try {
            setIsFetchingAdminToken(true);
            const data: RefreshAdminUserAccessMutation = await client.request(
              RefreshAdminUserAccessDocument,
              {
                input: {
                  id: activeOrganization.id,
                },
              },
            );

            setIsFetchingAdminToken(false);
            setAgentIsActive(false);

            if (
              data &&
              data.__typename === "Mutation" &&
              data.refreshAdminUserAccess?.__typename === "User"
            ) {
              const clientCredentials =
                data.refreshAdminUserAccess.clientCredentials?.[0];
              const expiration = jwtDecode<JwtPayload>(
                clientCredentials?.token!,
              ).exp!;
              setCookie(
                "ops-grantAdminUserAccess",
                {
                  userId: data.refreshAdminUserAccess.id,
                  jwt: clientCredentials?.token,
                  profile: clientCredentials?.organization?.profile,
                  id: clientCredentials?.organization?.id,
                  email: data.refreshAdminUserAccess.email,
                  expires: new Date(expiration * 1000),
                },
                {
                  httpOnly: false,
                  path: "/",
                  secure: process.env.NEXT_PUBLIC_AUTH_COOKIE_SSL === "true",
                  sameSite: "lax",
                  domain:
                    process.env.NEXT_PUBLIC_AUTH_COOKIE_DOMAIN ?? "localhost",
                  expires: new Date(expiration * 1000),
                },
              );
              const agentRoles = jwtDecode<JwtPayload & { br: string[] }>(
                clientCredentials?.token!,
              ).br;
              const adminUserAccessOrganization = [
                {
                  jwt: clientCredentials?.token,
                  id: clientCredentials?.organization?.id,
                  profile: clientCredentials?.organization?.profile,
                  roles: [UserRole.ADMIN],
                  isAdminUserAccessOrg: true,
                  adminUserAccessOrgExpires: new Date(expiration * 1000),
                  agentRoles,
                },
              ];
              setOldOrganizations(adminUserAccessOrganization as any);
            }
          } catch (error) {
            setIsFetchingAdminToken(false);
            setAgentIsActive(false);
            // eslint-disable-next-line no-console
            console.log(error);
          }
        } else {
          // Route back to ops landing page if agent is inactive
          router.push(OPS_URL);
          deleteCookie("ops-grantAdminUserAccess", {
            httpOnly: false,
            path: "/",
            secure: process.env.NEXT_PUBLIC_AUTH_COOKIE_SSL === "true",
            sameSite: "lax",
            domain: process.env.NEXT_PUBLIC_AUTH_COOKIE_DOMAIN ?? "localhost",
          });
        }
      }
    }
  }, 10_000);

  const {
    data: organizations,
    error: organizationsError,
    mutate,
  } = useSWR<OrganizationWithJWT[]>(
    [
      session?.user ? "organizations" : null,
      oldOrganizations,
      setOldOrganizations,
      false,
    ],
    organizationsFetcher,
    {
      refreshInterval: 3 * 1000 * 60,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      refreshWhenHidden: true,
    },
  );

  /**
   * After fetching organizations, we set the `activeOrganization` state
   * and active organization cookie. Logic is included which checks if the
   * current route is a 404 page and if so, uses the route to determine the
   * active organization.
   */
  if (
    activeOrganization === undefined &&
    organizations &&
    organizations.length > 0
  ) {
    if (router.pathname.includes("404")) {
      const orgId = router.asPath
        .split("/")
        .find((string) => string.startsWith("og"));

      if (orgId) {
        const activeOrg = organizations.find(
          (organization) => organization.id === orgId,
        );

        setActiveOrganization(activeOrg);

        setCookie("highnote.active-organization", activeOrg?.id, {
          httpOnly: false,
          path: "/",
          secure: process.env.NEXT_PUBLIC_AUTH_COOKIE_SSL === "true",
          sameSite: "lax",
          domain: process.env.NEXT_PUBLIC_AUTH_COOKIE_DOMAIN ?? "localhost",
        });
      } else {
        const activeOrg =
          organizations.find((org) => org.id === activeOrganizationId) ??
          organizations[0];

        setActiveOrganization(activeOrg);

        setCookie("highnote.active-organization", activeOrg.id, {
          httpOnly: false,
          path: "/",
          secure: process.env.NEXT_PUBLIC_AUTH_COOKIE_SSL === "true",
          sameSite: "lax",
          domain: process.env.NEXT_PUBLIC_AUTH_COOKIE_DOMAIN ?? "localhost",
        });
      }
    } else {
      const activeOrg =
        organizations.find((org) => org.id === activeOrganizationId) ??
        organizations[0];

      setActiveOrganization(activeOrg);

      setCookie("highnote.active-organization", activeOrg.id, {
        httpOnly: false,
        path: "/",
        secure: process.env.NEXT_PUBLIC_AUTH_COOKIE_SSL === "true",
        sameSite: "lax",
        domain: process.env.NEXT_PUBLIC_AUTH_COOKIE_DOMAIN ?? "localhost",
      });
    }
  }

  // Potentially update token on route change.
  /**
   * This effect attempts a token refresh every time the route changes
   * as long we're not navigating to the auth pages.
   */
  useEffect(() => {
    const handleRouteChange = (url: string) => {
      if (triggerTokenRefresh && !url.includes("/auth/")) {
        triggerTokenRefresh();
      }
    };
    router.events.on("routeChangeStart", handleRouteChange);
    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, [router.events, triggerTokenRefresh]);

  /**
   * This effect sets the `agentIsActive` state to true when `isAdminUserAccessOrg`
   * on the `activeOrganization` state is true every time the route changes.
   */
  useEffect(() => {
    const handleRouteChange = () => {
      if (activeOrganization?.isAdminUserAccessOrg) {
        setAgentIsActive(true);
      }
    };
    router.events.on("routeChangeStart", handleRouteChange);
    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, [activeOrganization?.isAdminUserAccessOrg, router.events]);

  /**
   * This effect runs whenever the organizations change and updates the
   * active organization state and cookie. Special handling is included
   * for when the `activeOrganizationId` and the `achorTag`
   * query params are present.
   */
  useEffect(() => {
    // No need to do anything until organizations have loaded.
    if (organizations) {
      // Dashboard uses this method of prefixing
      if ("id" in router.query) {
        const activeOrg = organizations.find(
          (organization) => organization.id === (router.query.id as string),
        );

        if (activeOrg) {
          setActiveOrganization(activeOrg);

          setCookie("highnote.active-organization", activeOrg.id, {
            httpOnly: false,
            path: "/",
            secure: process.env.NEXT_PUBLIC_AUTH_COOKIE_SSL === "true",
            sameSite: "lax",
            domain: process.env.NEXT_PUBLIC_AUTH_COOKIE_DOMAIN ?? "localhost",
          });
        } else {
          setActiveOrganization(organizations[0]);

          setCookie("highnote.active-organization", organizations[0].id, {
            httpOnly: false,
            path: "/",
            secure: process.env.NEXT_PUBLIC_AUTH_COOKIE_SSL === "true",
            sameSite: "lax",
            domain: process.env.NEXT_PUBLIC_AUTH_COOKIE_DOMAIN ?? "localhost",
          });
          void router.push(`/organizations/${organizations[0].id}/home`);
        }
      }

      // On route to home or dashboard this is how we will set id
      if ("activeOrganizationId" in router.query) {
        const activeOrg = organizations.find(
          (organization) =>
            organization.id === (router.query.activeOrganizationId as string),
        );

        setActiveOrganization(activeOrg);

        setCookie("highnote.active-organization", activeOrg?.id, {
          httpOnly: false,
          path: "/",
          secure: process.env.NEXT_PUBLIC_AUTH_COOKIE_SSL === "true",
          sameSite: "lax",
          domain: process.env.NEXT_PUBLIC_AUTH_COOKIE_DOMAIN ?? "localhost",
        });

        const { activeOrganizationId, ...restQuery } = router.query;

        /**
         * When routing from the dashboard to a specific slug in the docs,
         * "anchorTag" is included in the query params.
         */
        if ("anchorTag" in restQuery) {
          const { anchorTag, ...queryWithoutAnchorTag } = restQuery;

          if (anchorTag && typeof anchorTag === "string") {
            void router.push(
              {
                ...router,
                query: { ...queryWithoutAnchorTag },
                hash: anchorTag,
              },
              undefined,
              {
                shallow: true,
              },
            );
          }
          return;
        }
        void router.push({ ...router, query: { ...restQuery } }, undefined, {
          shallow: true,
        });
      }
    }
  }, [organizations, router]);

  /**
   * If the organizations fetcher function errors, we render the app with a `loginError`
   * prop passed to the context provider.
   */
  if (organizationsError) {
    return (
      <CommonAppContext.Provider
        value={{
          loading: false,
          user: undefined,
          loginError: isLoggingOut
            ? undefined
            : { message: organizationsError.message },
          localStore,
        }}
      >
        {children}
      </CommonAppContext.Provider>
    );
  }

  /**
   * If there are no organizations but no error, we just render the app
   */
  if (!organizations) {
    return (
      <CommonAppContext.Provider
        value={{
          loading: isLoading,
          user: session?.user,
          clearAppState,
          localStore,
        }}
      >
        {children}
      </CommonAppContext.Provider>
    );
  }

  /**
   * If organizations are an empty array, we show the organization display
   * component instead of the app
   */
  if (organizations.length === 0) {
    return (
      <CommonAppContext.Provider
        value={{
          loading: isLoading,
          user: session?.user,
          organizations,
          mutate,
          localStore,
        }}
      >
        <OrganizationDisplay />
      </CommonAppContext.Provider>
    );
  }

  /**
   * If there are organizations, we render the app and pass them to the context provider
   */
  return (
    <CommonAppContext.Provider
      value={{
        loading: isLoading,
        organizations,
        activeOrganization,
        triggerTokenRefresh,
        clearAppState,
        user: {
          ...session?.user,
          id: session?.user.id as string,
          roles: activeOrganization?.roles,
        },
        mutate,
        localStore,
      }}
    >
      {children}
    </CommonAppContext.Provider>
  );
};
