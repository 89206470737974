import { useFormikContext } from "formik";
import { useEffect } from "react";

import { ErrorMessage } from "./ErrorMessage";

export const CheckboxField = ({
  checked,
  name,
  onChange,
  label,
}: Readonly<{
  checked: boolean;
  name: string;
  onChange: () => void;
  label: string;
}>): JSX.Element => {
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    setFieldValue(name, checked);
  }, [checked, name, setFieldValue]);

  return (
    <>
      <label className="hover:bg-bone relative flex cursor-pointer items-center rounded-md px-1 py-1 text-xs">
        <input
          checked={checked}
          className="border-ash mr-2 h-6 w-6 cursor-pointer rounded text-black checked:bg-black focus:ring-transparent focus:ring-offset-0"
          onChange={onChange}
          type="checkbox"
        />
        {label}
      </label>

      <ErrorMessage keyPath={name} />
    </>
  );
};
