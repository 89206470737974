"use client";

import { CommonAppContext } from "@bay1/data";
import classNames from "classnames";
import { trackGoal } from "fathom-client";
import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";

import { ArrowButton } from "..";
import { DASHBOARD_URL, MARKETING_URL } from "../urlHelper";
import { UserMenu, UserMobileMenu } from "./UserMenu";

export const AccountDesktopNav = (): JSX.Element => {
  const { user } = React.useContext(CommonAppContext);
  const router = useRouter();
  const { pathname } = router;
  return (
    <div className="hidden items-center justify-end text-xs lg:flex">
      {!user || user.email === undefined ? (
        <>
          {!pathname.includes("/docs") && (
            <Link
              href={`${MARKETING_URL}/docs`}
              passHref
              className="group relative mx-5 hidden cursor-pointer text-sm md:block"
              data-testid="user-menu-desktop::documentation"
            >
              <span className="hidden xl:block">Documentation</span>
              <span className="hidden lg:block xl:hidden">Docs</span>
              <span className="absolute left-0 block h-0.5 w-0 origin-bottom-right bg-black duration-100 ease-in-out group-hover:w-full group-hover:origin-bottom-left" />
            </Link>
          )}
          <a
            className={classNames("group relative", {
              "hover:bg-ash mr-1.5 rounded-lg py-2.5 px-3 text-xs opacity-60 hover:opacity-100":
                router.pathname.includes("/docs"),
              "mr-4 text-sm": !router.pathname.includes("/docs"),
            })}
            data-testid="signIn"
            href={`${DASHBOARD_URL}/auth/signin`}
            onClick={() => trackGoal("F5UFPIFU", 0)}
          >
            Sign in
            <span
              className={classNames(
                "absolute left-0 block h-0.5 w-0 origin-bottom-right bg-black duration-100 ease-in-out group-hover:w-full group-hover:origin-bottom-left",
                {
                  hidden: router.pathname.includes("/docs"),
                },
              )}
            />
          </a>
          <a
            className="button h-10 bg-black text-white"
            href={`${DASHBOARD_URL}/auth/signin?screen_hint=signup`}
            onClick={() => trackGoal("PKG7QTYP", 0)}
          >
            {pathname.includes("/docs") ? "Get API Keys" : "Get Started"}
            <ArrowButton isPrimary />
          </a>
        </>
      ) : (
        <UserMenu user={user} />
      )}
    </div>
  );
};

export const AccountMobileNav = ({
  children,
}: Readonly<React.PropsWithChildren<unknown>>): JSX.Element => {
  const { user } = React.useContext(CommonAppContext);
  const router = useRouter();
  const { pathname } = router;
  const pathsWithoutNavDetails = ["/", "/about", "/careers", "/contact"];

  return (
    <div className="">
      {!user || user.email === undefined ? (
        <>
          <div className="p-4">
            <a
              className="button button-transparent"
              href={`${DASHBOARD_URL}/auth/signin`}
              onClick={() => trackGoal("TOGUZQVE", 0)}
            >
              Sign in{" "}
              <svg
                className="button-arrow"
                fill="none"
                height="20"
                viewBox="0 0 20 20"
                width="20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 1L18 10M18 10L9 19M18 10L-3.93402e-07 10"
                  strokeWidth="2"
                />
              </svg>
            </a>
          </div>
        </>
      ) : (
        <>
          {pathname.includes("/docs") ||
          pathsWithoutNavDetails.includes(pathname) ? (
            ""
          ) : (
            <>{children}</>
          )}
          <UserMobileMenu user={user} />
        </>
      )}
    </div>
  );
};
