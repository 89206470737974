/* eslint-disable fp/no-mutation */
/* eslint-disable fp/no-let */
import {
  ApolloClient,
  ApolloLink,
  from,
  HttpLink,
  InMemoryCache,
  type NormalizedCacheObject,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { getMainDefinition } from "@apollo/client/utilities";
import { IntegrationEnvironment } from "@bay1/sdk/generated/graphql";
import { ErrorModalContext } from "@bay1/ui/components/ErrorModalContext";
import { ToastContext } from "@bay1/ui/components/toasts/ToastContext";
import * as Sentry from "@sentry/nextjs";
import { SentryLink } from "apollo-link-sentry";
import { getCookie, hasCookie } from "cookies-next";
import merge from "deepmerge";
import { dequal } from "dequal/lite";
import type { GetServerSidePropsContext, NextApiRequest } from "next";
import { useContext, useMemo } from "react";

import possibleTypes from "../generated/possibleTypes.json";
import { generateClientErrorMessage } from "./helpers";
import { typePolicies } from "./policies";

type RequestContext = {
  req: GetServerSidePropsContext["req"] | NextApiRequest;
};

type ApolloClientOptions = {
  requestContext?: RequestContext;
  triggerToastMutation?: (data: any, success: boolean) => void;
  triggerErrorModal?: (errorData: any) => void;
};

let accessToken: string | undefined;
let accessTokenExpires: number | undefined;
let environment: IntegrationEnvironment | undefined;
let apolloClient: ApolloClient<NormalizedCacheObject> | undefined;

const getActiveOrganizationToken = async (ctx?: RequestContext) => {
  if (typeof window === "undefined") {
    if (!ctx)
      throw new Error(
        "Required parameter of type `RequestContext` is undefined.",
      );

    if (!ctx.req)
      throw new Error(
        "Required property `req` on parameter of type `RequestContext` is undefined.",
      );

    // if (ctx.req.url?.includes("refreshAdminUserAccess")) {
    //   const activeOrganizationCookie =
    //     ctx.req.cookies["ops-grantAdminUserAccess"];
    //   if (activeOrganizationCookie) {
    //     const activeOrganization = JSON.parse(activeOrganizationCookie);

    //     return {
    //       ...activeOrganization,
    //       accessToken: activeOrganization.jwt,
    //       accessTokenExpires: new Date(activeOrganization.expires).getTime(),
    //     };
    //   }
    // }

    const { getToken } = await import("next-auth/jwt");

    const session = await getToken({ req: ctx.req });

    const activeOrganization = session?.user.organizations.find(
      (org) => org.id === session.user.activeOrganizationId,
    );

    return {
      ...activeOrganization,
      accessToken: session?.user.activeOrganizationToken,
      accessTokenExpires: session?.user.activeOrganizationTokenExpires,
    };
  } else {
    const { getSession } = await import("next-auth/react");
    const session = await getSession();

    const activeOrganization = session?.user.organizations.find(
      (org) => org.id === session.user.activeOrganizationId,
    );

    if (hasCookie("ops-grantAdminUserAccess")) {
      const activeOrganization = JSON.parse(
        getCookie("ops-grantAdminUserAccess") as string,
      );

      return {
        ...activeOrganization,
        accessToken: activeOrganization.jwt,
        accessTokenExpires: new Date(activeOrganization.expires).getTime(),
      };
    }

    return {
      ...activeOrganization,
      accessToken: session?.user.activeOrganizationToken,
      accessTokenExpires: session?.user.activeOrganizationTokenExpires,
    };
  }
};

const createAuthLink = (ctx?: RequestContext) =>
  setContext(async ({ operationName }, { headers }) => {
    if (typeof window === "undefined") {
      if (operationName === "LoginUser") {
        return {
          headers: {
            ...headers,
            Authorization: `Basic ${Buffer.from(
              `${process.env.HIGHNOTE_PLATFORM_APPLICATION_KEY as string}:`,
            ).toString("base64")}`,
          },
        };
      } else {
        throw new Error(
          "Apollo Client requests are not supported on the server.",
        );
      }
    }

    const hasValidAccessToken =
      accessToken && accessTokenExpires && Date.now() < accessTokenExpires;

    if (hasValidAccessToken && hasCookie("ops-grantAdminUserAccess")) {
      const activeOrganization = JSON.parse(
        getCookie("ops-grantAdminUserAccess") as string,
      );

      accessToken = activeOrganization.jwt;
      accessTokenExpires = new Date(activeOrganization.expires).getTime();
      environment = activeOrganization.environment;
      return {
        headers: {
          ...headers,
          Authorization: `Bearer ${accessToken}`,
        },
      };
    }

    if (!hasValidAccessToken) {
      const organization = await getActiveOrganizationToken(ctx);
      accessToken = organization?.accessToken;
      accessTokenExpires = organization?.accessTokenExpires;
      environment = organization?.environment;
    }

    return {
      headers: {
        ...headers,
        Authorization: `Bearer ${accessToken}`,
      },
    };
  });

const createErrorLink = (triggerErrorModal?: (errorData: any) => void) =>
  onError(({ graphQLErrors, networkError, operation, response }) => {
    Sentry.withScope((scope) => {
      scope.setTransactionName(operation.operationName);
      scope.setContext("apolloGraphQLOperation", {
        operationName: operation.operationName,
        variables: operation.variables,
        extensions: operation.extensions,
      });

      if (graphQLErrors) {
        for (const error of graphQLErrors) {
          const isBadCredentialsError =
            error.message.startsWith("Bad credentials");

          if (!isBadCredentialsError)
            Sentry.captureMessage(error.message, {
              level: "error",
              fingerprint: ["{{ default }}", "{{ transaction }}"],
              contexts: {
                apolloGraphQLError: {
                  error,
                  message: error.message,
                  extensions: error.extensions,
                },
              },
              tags: {
                requestId: response?.extensions?.requestId as string,
              },
            });

          console.error(
            `[GraphQL error]: Message: ${error.message}, Operation: ${operation.operationName}`,
          );
        }
      }

      if (networkError) {
        Sentry.captureMessage(
          generateClientErrorMessage(networkError.message),
          {
            level: "error",
            contexts: {
              apolloNetworkError: {
                error: networkError,
                extensions: (networkError as any).extensions,
              },
            },
            tags: {
              requestId: response?.extensions?.requestId as string,
              code: (networkError as any).result?.extensions?.code as string,
            },
          },
        );

        console.error(`[Network error]: ${JSON.stringify(networkError)}`);

        if (triggerErrorModal && (networkError as any).statusCode === 429) {
          triggerErrorModal((networkError as any).result?.extensions.rateLimit);
        }
      }
    });
  });

export function createApolloClient(
  options?: ApolloClientOptions,
  opsEnvironment?: IntegrationEnvironment,
) {
  const httpLink = new HttpLink({
    uri: () => {
      if (hasCookie("ops-grantAdminUserAccess")) {
        const adminUserAccessCookie = JSON.parse(
          getCookie("ops-grantAdminUserAccess") as string,
        );
        environment = adminUserAccessCookie.profile.environment;
      }

      if (opsEnvironment) {
        environment = opsEnvironment;
      }

      return `${
        environment === IntegrationEnvironment.TEST
          ? process.env.NEXT_PUBLIC_TEST_API_URL
          : process.env.NEXT_PUBLIC_API_URL
      }/graphql`;
    },
  });

  const authLink = createAuthLink(options?.requestContext);

  const errorLink = createErrorLink(options?.triggerErrorModal);

  const sentryLink = new SentryLink({
    setTransaction: false,
    setFingerprint: false,
    attachBreadcrumbs: {
      includeVariables: true,
      includeError: true,
    },
  });

  const toastLink = new ApolloLink((operation, forward) => {
    const definition = getMainDefinition(operation.query);
    const isMutation =
      definition.kind === "OperationDefinition" &&
      definition.operation === "mutation";

    return forward(operation).map((result) => {
      if (!isMutation || !options?.triggerToastMutation) return result;

      const operationName = definition.name?.value;

      if (
        operationName === "LoginUser" ||
        operationName === "InviteUser" ||
        operationName === "UpdateUser" ||
        operationName === "CreateDocumentDownloadUrl"
      )
        return result;

      if (result.data) {
        const selection =
          definition.selectionSet.selections[0].kind === "Field"
            ? definition.selectionSet.selections[0].name.value
            : "";

        const resultTypename = result.data[selection].__typename;

        if (
          resultTypename === "UserError" ||
          resultTypename === "AccessDeniedError"
        ) {
          options.triggerToastMutation(result, false);
        } else {
          options.triggerToastMutation(result, true);
        }
      }

      if (result.errors) {
        options.triggerToastMutation(result, false);
      }

      return result;
    });
  });

  return new ApolloClient({
    ssrMode: typeof window === "undefined",
    link: from([toastLink, errorLink, sentryLink, authLink, httpLink]),
    cache: new InMemoryCache({
      possibleTypes,
      typePolicies,
    }),
    connectToDevTools: process.env.NODE_ENV === "development",
  });
}

export function useApollo(initialState?: NormalizedCacheObject) {
  const { triggerToastMutation } = useContext(ToastContext);
  const { triggerErrorModal } = useContext(ErrorModalContext);

  return useMemo(() => {
    const _apolloClient =
      apolloClient ??
      createApolloClient({ triggerToastMutation, triggerErrorModal });

    // If your page has Next.js data fetching methods that use Apollo Client,
    // the initial state gets hydrated here
    if (initialState) {
      // Get existing cache, loaded during client side data fetching
      const existingCache = _apolloClient.extract();

      // Merge the existing cache into data passed from getStaticProps/getServerSideProps
      const data = merge(initialState, existingCache, {
        // combine arrays using object equality (like in sets)
        arrayMerge: (destinationArray, sourceArray) => [
          ...sourceArray,
          ...destinationArray.filter((d) =>
            sourceArray.every((s) => !dequal(d, s)),
          ),
        ],
      });

      // Restore the cache with the merged data
      _apolloClient.cache.restore(data);
    }

    // For SSG and SSR always create a new Apollo Client
    if (typeof window === "undefined") return _apolloClient;

    // Create the Apollo Client once in the client
    if (!apolloClient) apolloClient = _apolloClient;

    return _apolloClient;
  }, [initialState, triggerErrorModal, triggerToastMutation]);
}
