import { CommonAppContext } from "@bay1/data";
import { SUPPORT_EMAIL } from "@bay1/data/helpers";
import { PageLoader } from "@bay1/ui";
import React, { useCallback, useContext, useEffect, useState } from "react";

export default function Verify(): JSX.Element {
  const { activeOrganization } = useContext(CommonAppContext);

  const [emailResent, setEmailResent] = useState(false);
  const [initialEmailSent, setInitialEmailSent] = useState<boolean>();

  const handleSendVerificationEmail = useCallback(
    async (isResending: boolean) => {
      try {
        if (activeOrganization?.isAdminUserAccessOrg) {
          return;
        }

        const response = await fetch("/api/sendVerificationEmail", {
          method: "POST",
        });

        const data = await response.json();
        if (data.status === "pending" && data.type === "verification_email") {
          if (!isResending) {
            setInitialEmailSent(true);
          }
          if (isResending) {
            setEmailResent(true);
          }
        }
      } catch {}
    },
    [activeOrganization?.isAdminUserAccessOrg],
  );

  useEffect(() => {
    handleSendVerificationEmail(false);
  }, [handleSendVerificationEmail]);

  return initialEmailSent === undefined ? (
    <PageLoader />
  ) : (
    <div className="flex h-screen w-screen items-center justify-center antialiased">
      <div className="rounded-highnote mx-auto my-8 mt-10 w-full max-w-lg overflow-hidden bg-white p-8 text-left md:mt-24">
        <div className="text-left">
          {!emailResent ? (
            <>
              <h3 className="text-lg font-medium leading-6">
                Verify Your Email
              </h3>
              <div className="mt-7">
                <p className="text-sm">
                  For security purposes, we need to verify your email before you
                  can access your account.
                </p>
                {initialEmailSent && (
                  <p className="mt-4 text-sm">
                    A verification link was sent to the email address provided.
                    If you have not received an email after a few minutes,
                    please check your spam folder.
                  </p>
                )}

                <p className="mt-4 cursor-pointer text-sm text-gray-500">
                  Didn&apos;t see the email?{" "}
                  <span
                    className="underline hover:text-black"
                    onClick={() => handleSendVerificationEmail(true)}
                  >
                    Send it again
                  </span>
                </p>
              </div>
            </>
          ) : (
            <>
              {" "}
              <h3 className="text-lg font-medium leading-6">Email Resent</h3>
              <div className="mt-7">
                <p className="text-sm">
                  We resent the verification link to the email address provided.
                  If you have not received an email after a few minutes, please
                  check your spam folder.
                </p>
                <p className="mt-4 cursor-pointer text-sm text-gray-500">
                  Still didn&apos;t see the email?{" "}
                  <a className="underline" href={`mailto:${SUPPORT_EMAIL}`}>
                    Contact Support
                  </a>
                </p>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
