/* eslint-disable fp/no-mutation */

"use client";

import { CommonAppContext } from "@bay1/data";
import type { SessionUser } from "@bay1/data/CommonAppContext";
import { Transition } from "@headlessui/react";
import classNames from "classnames";
import Link from "next/link";
import { useRouter } from "next/router";
import type { SyntheticEvent } from "react";
import { useCallback, useContext, useState } from "react";

import { DASHBOARD_URL, MARKETING_URL } from "../urlHelper";
import Avatar from "./Avatar";

async function getCsrfToken(): Promise<string | undefined> {
  const response = await fetch(`${DASHBOARD_URL}/api/auth/csrf`, {
    credentials: "include",
  });

  if (response.ok) {
    const { csrfToken } = (await response.json()) as { csrfToken: string };

    return csrfToken;
  }

  return undefined;
}

export async function signOut(
  clearAppState: (() => void) | undefined,
): Promise<void> {
  const clientId = encodeURIComponent(
    process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID ?? "",
  );
  const returnTo = encodeURIComponent(`${DASHBOARD_URL}/auth/signin`);
  const body = new URLSearchParams();

  body.append("json", "true");
  body.append(
    "callbackUrl",
    `${process.env.NEXT_PUBLIC_AUTH0_DOMAIN}/v2/logout?clientId=${clientId}&returnTo=${returnTo}`,
  );
  body.append("csrfToken", (await getCsrfToken()) ?? "");

  const response = await fetch(`${DASHBOARD_URL}/api/auth/signout`, {
    method: "POST",
    credentials: "include",

    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },

    body,
  });

  if (response.ok) {
    const data = (await response.json()) as { url: Location };
    if (clearAppState) {
      await clearAppState();
      window.location = data.url;
    }
  }
}

export const UserMenu = ({
  user,
  disabled,
}: {
  user: SessionUser;
  disabled?: boolean;
}): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const { organizations, activeOrganization, clearAppState } =
    useContext(CommonAppContext);
  const router = useRouter();

  const handleSetIsOpen = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleOnBlur = useCallback(
    () =>
      setTimeout(() => {
        setIsOpen(false);
      }, 100),
    [],
  );

  const handleChangeOrganizations = useCallback(
    (event: SyntheticEvent<HTMLButtonElement>) => {
      if (router.pathname === "/") {
        window.location.href = `${MARKETING_URL}/?activeOrganizationId=${event.currentTarget.value}`;
      } else if (router.pathname.includes("/docs")) {
        window.location.href = `${MARKETING_URL}/docs?activeOrganizationId=${event.currentTarget.value}`;
      } else {
        window.location.href = `${DASHBOARD_URL}/organizations/${event.currentTarget.value}/home`;
      }
    },
    [router.pathname],
  );

  const handleSignout = useCallback(
    async (event: SyntheticEvent<HTMLAnchorElement>) => {
      event.preventDefault();
      signOut(clearAppState);
    },
    [clearAppState],
  );

  return (
    <>
      {!router.pathname.includes("/docs") &&
        (activeOrganization?.id === undefined ? (
          <Link
            href={`${MARKETING_URL}/docs/`}
            passHref
            className="group relative mx-3 hidden cursor-pointer text-sm md:block"
            data-testid="user-menu-desktop::documentation"
          >
            <span className="hidden xl:block">Documentation</span>
            <span className="hidden lg:block xl:hidden">Docs</span>
            <span className="absolute left-0 block h-0.5 w-0 origin-bottom-right bg-black duration-100 ease-in-out group-hover:w-full group-hover:origin-bottom-left" />
          </Link>
        ) : (
          <Link
            href={{
              pathname: `${MARKETING_URL}/docs/`,

              query: {
                activeOrganizationId: activeOrganization.id,
              },
            }}
            passHref
            className="group relative mx-3 hidden cursor-pointer text-sm md:block"
            data-testid="user-menu-desktop::documentation"
          >
            <span className="hidden xl:block">Documentation</span>
            <span className="hidden lg:block xl:hidden">Docs</span>
            <span className="absolute left-0 block h-0.5 w-0 origin-bottom-right bg-black duration-100 ease-in-out group-hover:w-full group-hover:origin-bottom-left" />
          </Link>
        ))}
      {!router.pathname.includes("/organizations") &&
        (activeOrganization?.id === undefined ? (
          <Link
            href={DASHBOARD_URL}
            passHref
            className={classNames("group relative hidden lg:block", {
              "hover:bg-ash mx-0.5 rounded-lg py-2.5 px-3 text-xs opacity-60 hover:opacity-100":
                router.pathname.includes("/docs"),
              "mx-3 text-sm": !router.pathname.includes("/docs"),
            })}
            data-testid="user-menu-desktop::dashboard"
          >
            Dashboard
            <span
              className={classNames(
                "absolute left-0 block h-0.5 w-0 origin-bottom-right bg-black duration-100 ease-in-out group-hover:w-full group-hover:origin-bottom-left",
                {
                  hidden: router.pathname.includes("/docs"),
                },
              )}
            />
          </Link>
        ) : (
          <Link
            href={{
              pathname: `${DASHBOARD_URL}/organizations/${activeOrganization.id}/home`,

              query: { activeOrganizationId: activeOrganization.id },
            }}
            passHref
            className={classNames("group relative hidden lg:block", {
              "hover:bg-ash mx-0.5 rounded-lg py-2.5 px-3 text-xs opacity-60 hover:opacity-100":
                router.pathname.includes("/docs"),
              "mx-3 text-sm": !router.pathname.includes("/docs"),
            })}
            data-testid="user-menu-desktop::dashboard"
          >
            Dashboard
            <span
              className={classNames(
                "absolute left-0 block h-0.5 w-0 origin-bottom-right bg-black duration-100 ease-in-out group-hover:w-full group-hover:origin-bottom-left",
                {
                  hidden: router.pathname.includes("/docs"),
                },
              )}
            />
          </Link>
        ))}
      <span className="relative z-0 ml-0 inline-flex rounded-sm lg:ml-3">
        <button
          aria-expanded="false"
          aria-haspopup="true"
          className="bg-ash group h-10 w-56 cursor-default rounded-full border border-transparent px-1 text-left text-xs ring-black lg:cursor-pointer lg:hover:border-black lg:focus:ring-1"
          data-testid="user-menu-desktop::open"
          disabled={!organizations || disabled}
          onBlur={handleOnBlur}
          onClick={handleSetIsOpen}
          type="button"
        >
          <span className="flex w-full items-center justify-between">
            <span className="flex min-w-0 items-center justify-between space-x-1.5">
              <span className="relative inline-block">
                <span className="block rounded-full">
                  <Avatar user={user} />
                </span>
              </span>
              <span className="flex min-w-0 flex-1 flex-col">
                <span className="truncate text-xs">
                  {activeOrganization?.profile.environment && (
                    <span className="text-xxs mr-1 rounded-full bg-black/10 px-1 font-medium uppercase tracking-wide">
                      {activeOrganization.profile.environment}
                    </span>
                  )}
                  {activeOrganization?.profile.displayName}
                </span>
              </span>
            </span>
            {/* Heroicon name: chevron-down */}
            <svg
              aria-hidden="true"
              className="mr-1 hidden h-5 w-5 shrink-0 lg:block"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                clipRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                fillRule="evenodd"
              />
            </svg>
          </span>
        </button>
        <Transition
          enter="transition ease-out duration-100"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
          show={isOpen}
        >
          <div className="relative inline-block text-left">
            <div
              aria-labelledby="menu-button"
              aria-orientation="vertical"
              className="rounded-rounded ring-ash divide-ash absolute right-0 z-20 mt-8 w-64 origin-top-right divide-y bg-white shadow-lg ring-1 focus:outline-none"
              role="menu"
              tabIndex={-1}
            >
              <div className="py-1" role="none">
                {organizations?.map((organization) => (
                  <Link
                    href={
                      router.pathname.includes("/organizations")
                        ? `${DASHBOARD_URL}/organizations/${organization.id}/home`
                        : `/${router.pathname.split("/")[1]}`
                    }
                    key={organization.id}
                  >
                    <button
                      className="hover:bg-bone relative block w-full truncate py-2.5 pl-2.5 pr-6 text-left text-xs"
                      data-testid="user-menu-desktop::select::organization"
                      id={organization.profile.environment}
                      onClick={handleChangeOrganizations}
                      type="button"
                      value={organization.id}
                    >
                      <span className="text-xxs mr-1 rounded-full bg-black/10 px-1 font-medium uppercase tracking-wide">
                        {organization.profile.environment}
                      </span>
                      {organization.profile.displayName}
                      {activeOrganization?.id === organization.id && (
                        <div className="bg-green absolute right-2.5 top-4 inline-flex h-2 w-2 rounded-full" />
                      )}
                    </button>
                  </Link>
                ))}
              </div>

              <div className="py-1" role="none">
                {!activeOrganization?.isAdminUserAccessOrg && (
                  <Link
                    href={`${DASHBOARD_URL}/profile`}
                    passHref
                    className="hover:bg-bone block rounded-t px-2.5 py-2.5 text-xs"
                    data-testid="user-menu-desktop::profile"
                    role="menuitem"
                  >
                    Profile
                  </Link>
                )}

                <a
                  className="hover:bg-bone block px-2.5 py-2.5 text-xs"
                  data-testid="user-menu-desktop::signout"
                  href={DASHBOARD_URL}
                  onClick={handleSignout}
                  role="menuitem"
                >
                  Sign Out
                </a>
              </div>
            </div>
          </div>
        </Transition>
      </span>
    </>
  );
};

export const UserMobileMenu = ({
  user,
}: {
  user: SessionUser;
}): JSX.Element => {
  const { activeOrganization, organizations, clearAppState } =
    useContext(CommonAppContext);

  const handleSignoutClick = useCallback(
    // eslint-disable-next-line sonarjs/no-identical-functions
    async (event: SyntheticEvent<HTMLAnchorElement>): Promise<void> => {
      event.preventDefault();

      signOut(clearAppState);
    },
    [clearAppState],
  );

  return (
    <>
      <div className="border-t py-6">
        <div className="grid gap-4">
          <a
            className="rounded-md text-base"
            data-testid="user-menu-mobile::marketing"
            href={String(MARKETING_URL)}
          >
            highnote.com
          </a>
          {activeOrganization?.id === undefined ? (
            <Link
              href={{
                pathname: `${MARKETING_URL}/docs/`,
              }}
              passHref
              className="rounded-md text-base"
              data-testid="user-menu-mobile::documentation"
            >
              Documentation
            </Link>
          ) : (
            <Link
              href={{
                pathname: `${MARKETING_URL}/docs/`,
                query: {
                  activeOrganizationId: activeOrganization.id,
                },
              }}
              passHref
              className="rounded-md text-base"
              data-testid="user-menu-mobile::documentation"
            >
              Documentation
            </Link>
          )}

          {activeOrganization?.id === undefined ? (
            <Link
              href={DASHBOARD_URL}
              passHref
              className="rounded-md text-base"
              data-testid="user-menu-mobile::dashboard"
            >
              Dashboard
            </Link>
          ) : (
            <Link
              href={{
                pathname: `${DASHBOARD_URL}/organizations/${activeOrganization.id}/home`,
                query: { activeOrganizationId: activeOrganization.id },
              }}
              passHref
              className="rounded-md text-base"
              data-testid="user-menu-mobile::dashboard"
            >
              Dashboard
            </Link>
          )}
        </div>
      </div>

      <div className="block md:inline-flex">
        <UserMenu disabled user={user} />
        <p className="flex py-2 text-base md:ml-4 md:inline-flex md:px-2">
          {organizations && organizations.length > 1 && (
            <Link
              href={`${DASHBOARD_URL}/auth/organizations`}
              passHref
              className="mr-4"
              data-testid="user-menu-mobile::organizationSwitcher"
            >
              Switch Organization
            </Link>
          )}
          {!activeOrganization?.isAdminUserAccessOrg && (
            <Link
              href={`${DASHBOARD_URL}/profile`}
              passHref
              className="mr-4"
              data-testid="user-menu-mobile::profile"
            >
              Profile
            </Link>
          )}
          <Link
            href="/"
            passHref
            data-testid="user-menu-mobile::signout"
            onClick={handleSignoutClick}
          >
            Sign Out
          </Link>
        </p>
      </div>
    </>
  );
};
